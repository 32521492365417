<template>
  <div>
    <v-row align="center" class="justify-center">
      <v-btn
            align='center'
            text
            color="primary" @click="print">Print
      </v-btn>
    </v-row>
    <v-row id="printMe">
      <v-card
        class="mx-auto mt-0"
        width="1000"
      >
      </v-card>
      <v-card
        class="mx-auto my-2"
        max-width="1000"
        width="1000"
      >
        <v-card-text>
            <div class="text--primary">
              <p><b>Homely Remedy Name:</b> {{remedies[0]['name']}}</p>
              <p><b>Description:</b> {{remedies[0]['description']}}</p>
              <p><b>Directive:</b>{{remedies[0]['directive']}}</p><br>
              <p><b>Expiry Date:</b> {{remedies[0]['expiryDate']}}</p><br>
              <p><b>Total Quantity:</b> {{remedies[0]['totalQuantity']}}</p><br>
              <p><b>Type:</b> {{remedies[0]['type']}}</p><br>
            </div>
        </v-card-text>
        <img height="50" width="50" src="../../assets/logo.png" >
      </v-card>
    </v-row>
    </div>
</template>

<script>
import homelyRemedyApi from '../../services/api/HomelyRemediesApi';
export default {
    name: 'homelyRemediesDetail',
    data() {
        return {
            remedies: '',
            homeId: '',
            remediesId: ''
        }
    },
    computed: {

    },
    created() {
        this.homeId = this.$route.params.homeId;
        this.remediesId = this.$route.params.remediesId;
        this.get_homely_registered_remedy_list ();
    },
    methods: {
       get_homely_registered_remedy_list () {
          this.loading = true
          return new Promise((resolve, reject) => {
              homelyRemedyApi.getRegisteredHomelyRemedy({page: '', count:'',home:this.homeId, q:'', orderBy:'', order:''})
                .then(remedies => {
                    this.remedies = remedies.result.filter(item => item.id === this.remediesId)
                    this.totalRemedies = this.remedies.length
                })
                .catch(error => {
                    return reject(error)
                })
          })
        },
        print () {
          this.$htmlToPaper('printMe');
        }
    }
}
</script>

<style scoped>

</style>